@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.field {
  & + .field {
    margin-top: 8px;

    @include mediaMdMin {
      margin-top: 12px;
    }
  }
}

.label {
  display: flex;
  flex: 1 1 auto;
}

.inner {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @include mediaMdMin {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & + * {
    margin-left: 16px;
  }
}

.systems {
  opacity: 0.5;

  @include mediaSmMax {
    margin: 6px 0 0;
    font-size: 13px;
    line-height: 16px;
  }

  @include mediaMdMin {
    font-size: 17px;
    line-height: 20px;
  }
}

.status {
  margin-top: 6px;
}

.submit {
  margin: 24px 0 0;

  @include mediaSmMax {
    width: 100%;
  }

  @include mediaMdMin {
    margin: 32px 0 0;
  }
}
