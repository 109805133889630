@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.name {
  margin: 0 0 16px;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.description {
  @include mediaSmMax {
    padding-right: 24px;
  }

  margin: 0 0 20px;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.5px;
}

.content {
  @include mediaSmMax {
    display: flex;
    border-bottom: 1px solid $black;
  }
}

.options {
  @include mediaSmMax {
    flex: 0 0 150px;
    border-left: 1px solid $black;
  }

  @include mediaMdMin {
    display: flex;
    border-bottom: 1px solid $black;
  }
}

.option {
  @include mediaSmMax {
    padding: 16px 0 24px 24px;

    & + .option {
      border-top: 1px solid $black;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  @include mediaMdMin {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding-bottom: 40px;

    & + .option {
      padding-left: 20px;
      padding-right: 10px;
      border-left: 1px solid $black;
    }
  }
}

.label,
.sign {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.label {
  margin: 0 0 9px;
}

.summary {
  display: flex;
  justify-content: flex-start;
}

.sign {
  flex: 0 0 auto;
  padding: 5px 7px 0 0;
  border-top: 1px solid $black;
}

.value {
  margin: -10px 0 0;
  flex: 1 1 auto;
  padding-left: 10px;
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: -2px;

  @include mediaMdMin {
    font-size: 55px;
    line-height: 60px;
  }
}
