@import '../../style/mixins';
@import '../../style/variables';

.component {
  width: 100%;
  max-width: 778px;
  padding: 40px 16px 16px;
  margin: 0 auto;

  @include mediaXss {
    padding: 16px 8px;
  }
}
