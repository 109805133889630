@import '../../style/mixins';
@import '../../style/variables';

.component {
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 -4px 16px rgba($mystic, 0.5);
}

.padding {
  padding: 24px 20px;

  @include mediaMdMin {
    padding: 48px 64px;
  }
}
