@import '../../style/mixins';
@import '../../style/variables';

.component {
  @include resetButtonStyle;

  display: block;
  width: 100%;
  padding: 24px 20px 52px;
  background-color: $mysticL;
  text-align: left;
  border-radius: 24px;
  box-shadow: 0 -4px 16px $mystic;
  margin-bottom: -36px;
  transition: background-color 0.2s ease;

  @include mediaMdMin {
    padding: 32px 64px 60px;

    @include hoverable {
      &:hover {
        background-color: lighten($mysticL, 2.5);
      }
    }
  }
}

.title {
  padding: 0;
  margin: 0 0 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;

  @include mediaMdMin {
    font-size: 17px;
  }
}

.detail {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;

  @include mediaMdMin {
    font-size: 23px;
    line-height: 30px;
  }
}
