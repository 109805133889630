.component {
  display: inline-block;
  position: relative;
  padding-left: 27px;

  img {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
