$bodyFont: 'Graphik', sans-serif;

$black: #000;
$gray: #A5A5A5;
$white: #FFF;
$green: #07A95B;
$red: #D22238;

$mysticD: #DBE6E8;
$mystic: #E2ECED;
$mysticL: #F0F6F6;

