@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.payment {
  border-bottom: 2px solid $mysticD;
  border-top: 2px solid $mysticD;

  & + .payment {
    border-top: none;
  }
}

.section {
  & + .section {
    margin-top: 16px;
  }
}

.row {
  @include mediaMdMin {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
  }
}

.col {
  margin: 0 0 12px;

  @include mediaMdMin {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 6px;
  }
}

.inputContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.1;
  min-height: 2.2em;
}

.inputLabel {
  padding: 11px 20px 13px 54px;
}

.field {
  & + .field {
    margin-top: 8px;

    @include mediaMdMin {
      margin-top: 12px;
    }
  }
}

.input {
  width: 100%;
}

.submit {
  margin: 24px 0 0;

  @include mediaSmMax {
    width: 100%;
  }

  @include mediaMdMin {
    margin: 32px 0 0;
  }
}

.blocked {
  margin-bottom: 16px;
}
