@import '../../style/mixins';
@import '../../style/variables';

.component {
  margin: 0 0 24px;
}

.title {
  padding: 0;
  margin: 0 0 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;

  @include mediaMdMin {
    font-size: 17px;
  }
}

.description {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;

  @include mediaMdMin {
    font-size: 23px;
    line-height: 30px;
  }
}
