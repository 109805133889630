@import './reset.scss';
@import './variables.scss';

@-ms-viewport {
  width: device-width;
}

html,
body,
:global(#root) {
  min-height: 100%;
}

html {
  -ms-overflow-style: scrollbar;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-family: $bodyFont;
  font-size: 16px;
  line-height: 1.375;
  word-wrap: break-word;
  word-break: break-word;
  color: $black;
  background-color: $mystic;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
