@import '../../style/mixins';
@import '../../style/variables';

.component {}

.field {
  & + .field {
    margin-top: 8px;

    @include mediaMdMin {
      margin-top: 12px;
    }
  }
}

.message {
  margin: 8px 0 0;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.submit {
  margin: 24px 0 0;

  @include mediaSmMax {
    width: 100%;
  }

  @include mediaMdMin {
    margin: 32px 0 0;
  }
}
