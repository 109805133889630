@import '../../style/mixins';
@import '../../style/variables';

.component {
  padding: 80px 48px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $mysticL;
}

.circle {
  width: 112px;
  height: 112px;
  margin: 0 0 40px;
  border-radius: 50%;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 60px 46px;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M53.08.45L19.32 34.2 6.92 21.8c-.6-.6-1.57-.6-2.17 0l-3.63 3.63c-.6.6-.6 1.58 0 2.18l17.12 17.12c.6.6 1.57.6 2.17 0L58.88 6.26c.6-.6.6-1.58 0-2.18L55.25.45c-.6-.6-1.57-.6-2.17 0z' fill='%232F929C'/%3E%3C/svg%3E");
}

.title {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: -1px;

  @include mediaMdMin {
    font-size: 36px;
    line-height: 40px;
  }
}

.description {
  margin: 0 0 32px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -1px;

  @include mediaMdMin {
    font-size: 23px;
    line-height: 30px;
  }
}

.link {
  display: inline-block;
  border-radius: 5px;
  border: 1px solid $black;
  padding: 19px 32px;
  font-size: 17px;
  line-height: 22px;
  text-decoration: none;
  color: inherit;
  transition: color 0.2s ease;
  cursor: pointer;

  @include hoverable {
    &:hover {
      color: rgba($black, 0.5);
    }
  }
}
