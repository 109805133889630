@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.section {
  padding: 24px 20px;

  @include mediaMdMin {
    padding: 48px 64px;
  }

  &:first-child {
    border-radius: 24px 24px 0 0;
  }

  &:last-child {
    border-radius: 0 0 24px 24px;
  }
}

.status {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
}

.recommendation {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.3px;
}

.colored {
  background-color: $mysticL;
}

.detail {
  margin-top: 32px;
}

.type {
  display: flex;
  margin: 0 0 16px;
}

.sign {
  margin-top: 12px;
  padding: 0 4px;
  border-top: 1px solid $black;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.3px;
}

.value {
  padding-left: 6px;
  font-weight: 600;
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -2px;
}

.from,
.label {
  align-self: flex-end;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -1px;
}

.from {
  padding: 0 6px 6px 0;
}

.label {
  padding: 0 0 6px 8px;
}

.description {
  margin: 0 0 32px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.3px;
}

.submit {
  @include mediaSmMax {
    width: 100%;
  }
}
