@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.head {
  @include resetButtonStyle;

  position: relative;
  width: 100%;
  padding: 20px 56px 22px 0;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: left;

  &[aria-expanded="true"]:after {
    transform: rotate(180deg);
  }

  &:after {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 18px;
    height: 10px;
    margin-top: -5px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 18px 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.98 1.39l-.72-.72a.44.44 0 00-.62 0L9.05 7.24 2.46.67a.44.44 0 00-.61 0l-.72.72a.44.44 0 000 .61l7.61 7.62c.18.17.45.17.62 0L16.98 2a.44.44 0 000-.61z' fill='%23000'/%3E%3C/svg%3E");
    content: '';
    transition: transform 0.2s ease;
  }
}

.body {
  position: relative;
  overflow: hidden;
  transition: height 0.2s ease;
}

.content {
  padding-bottom: 22px;
}
