@import '../../style/mixins';
@import '../../style/variables';

.container {
  position: relative;

  &:after {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 16px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9 13.82l-3.32-3.32a.32.32 0 00-.23-.1H11A5.67 5.67 0 006.7 1a5.69 5.69 0 103.72 10v.35c0 .09.03.17.1.23l3.31 3.32c.13.13.34.13.47 0l.61-.61a.33.33 0 000-.47zM6.7 11.06a4.37 4.37 0 110-8.75 4.37 4.37 0 010 8.75z' fill='%23000' opacity='.25'/%3E%3C/svg%3E");
    content: '';
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.input {
  display: block;
  border: none;
  width: 100%;
  margin: 0;
  padding: 18px 54px 18px 0;
  font-family: $bodyFont;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  outline: none;
  border-bottom: 1px solid rgba($black, 0.2);
  transition: border-color 0.15s ease;

  &:focus {
    border-color: $black;
  }
}

.suggestionsContainer {
  position: absolute;
  top: 95%;
  width: 100%;
  max-width: 320px;
  z-index: 1;
}

.suggestionsContainerOpen {
  max-height: 300px;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0 4px 12px $mystic;
}

.suggestionsList {
  background-color: $white;
  width: 100%;
}

.suggestion {
  @include resetButtonStyle;

  display: block;
  width: 100%;
  text-align: left;
  padding: 19px;
  cursor: pointer;

  &.suggestionHighlighted {
    background-color: $mysticL;
  }

  @include hoverable {
    &:hover,
    &:focus {
      background-color: rgba($mysticL, 0.5);
    }
  }
}
