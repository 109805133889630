@import '../../style/mixins';
@import '../../style/variables';

.list {
  margin: 12px 0 0;
  border-top: 1px solid $mysticD;
}

.item {
  padding: 12px 0;
  border-bottom: 1px solid $mysticD;
  font-size: 16px;
  line-height: 20px;

  @include mediaMdMin {
    font-size: 17px;
    line-height: 19px;
  }
}
