@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.row {
  @include mediaMdMin {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }
}

.col {
  @include mediaSmMax {
    &:first-child {
      margin: 0 0 16px;
    }
  }

  @include mediaMdMin {
    margin: 0;
    padding: 0 8px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.submit {
  margin: 24px 0 0;

  @include mediaSmMax {
    width: 100%;
  }

  @include mediaMdMin {
    margin: 32px 0 0;
  }
}

