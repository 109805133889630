@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.plan {
  margin: 0 0 32px;
}

.row {
  @include mediaMdMin {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
  }
}

.col {
  margin: 0 0 20px;

  @include mediaMdMin {
    padding: 0 8px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.title {
  margin: 0 0 4px;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.description {
  margin: 0 0 16px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.5px;
}

.submit {
  margin: 24px 0 0;

  @include mediaSmMax {
    width: 100%;
  }

  @include mediaMdMin {
    margin: 32px 0 0;
  }
}

