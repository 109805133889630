@import '../../style/mixins';
@import '../../style/variables';

.component {
  position: relative;
  padding-top: 18px;
}

.input {
  display: block;
  width: 100%;
  padding: 6px 0 14px;
  font-family: $bodyFont;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.5px;
  color: $black;
  background-color: $white;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: rgba($black, 0.2);
  box-shadow: none;
  outline: none;
  transition: border-color 0.15s ease, background-color 0.15s ease;

  &:-webkit-autofill {
    transition: all 9999s ease-in-out 0s;
    transition-property: background-color, color;
  }

  &[readonly],
  &[disabled] {
    opacity: 0.5;
    cursor: text;
  }

  &.textarea {
    resize: vertical;
    min-height: calc(1em + 20px);
  }

  &:focus {
    border-color: $black;
  }

  &.error {
    border-color: $red;
  }

  &::placeholder {
    color: rgba($gray, 0.6);
  }
}

.label {
  position: absolute;
  top: 0;
  display: block;
  font-size: 15px;
  line-height: 18px;
  color: $gray;
  pointer-events: none;
  will-change: transform;
  transform: translateY(24px);
  transition: transform 0.15s ease;
  transform-origin: 0 0;
}

.input:focus + .label,
.input:not(.empty) + .label {
  transform: translateY(0) scale(0.9);
}

.message {
  margin-top: 4px;
}
