@import '../../style/mixins';
@import '../../style/variables';

.component {
}

.head {
  background-color: $mysticL;
  display: flex;
  align-items: center;
  padding: 20px 24px 16px;
  border-radius: 10px 10px 0 0;
}

.body {
  padding: 22px 20px;
  background-color: $white;
  border: 4px solid $mysticL;
  border-radius: 0 0 10px 10px;
}

.content {
  flex: 1 1 auto;
}

.country {
  padding-left: 60px;
}

.title {
  display: flex;
  align-items: center;
}

.name {
  margin-left: 12px;
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  letter-spacing: -0.5px;
}

.currencies {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-end;
}

.list {
  display: flex;
  padding: 0;
  list-style: none;
}

.item {
  margin: 0;
  padding: 0;

  & + .item {
    margin-left: -8px;
  }
}

.currency {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 1px solid $mysticL;
  border-radius: 50%;
  color: $mysticL;
  font-size: 24px;
  line-height: 1;
  background-color: $black;
}

.options {
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.3px;

  strong {
    font-weight: 600;
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid;
  }
}

.option {
  position: relative;
  padding-left: 24px;

  &::before {
    position: absolute;
    left: 0;
    top: 1.5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8A8 8 0 110 8a8 8 0 0116 0zm-8.93 4.24l5.94-5.94c.2-.2.2-.53 0-.73l-.73-.73a.52.52 0 00-.73 0L6.71 9.68 4.45 7.42a.52.52 0 00-.73 0l-.73.73c-.2.2-.2.53 0 .73l3.35 3.36c.2.2.53.2.73 0z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 50% 50%;
  }

  & + .option {
    margin-top: 16px;
  }
}
