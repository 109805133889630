@import '../../style/mixins';
@import '../../style/variables';

.component {

}

.label {
  display: block;
  font-size: 23px;
  line-height: 30px;
  margin: 0 0 8px;
}

.field {
  display: flex;
  background-color: $mystic;
  border-radius: 5px;
}

.control {
  @include resetButtonStyle;

  display: flex;
  padding: 16px 20px;
  flex: 0 0 auto;
  border-radius: 5px;

  &:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    border-radius: 50%;
    background-color: $black;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
  }

  @include hoverable {
    &:hover,
    &:focus {
      background-color: $mysticD;
    }
  }

  &:active {
    background-color: $mysticD;
  }
}

.decrease:before {
  background-size: 12px 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.75.25H.25A.25.25 0 000 .5v1c0 .14.11.25.25.25h11.5c.14 0 .25-.11.25-.25v-1a.25.25 0 00-.25-.25z' fill='%23fff'/%3E%3C/svg%3E");
}

.increase:before {
  background-size: 12px 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.75 5.25h-5v-5A.25.25 0 006.5 0h-1a.25.25 0 00-.25.25v5h-5A.25.25 0 000 5.5v1c0 .14.11.25.25.25h5v5c0 .14.11.25.25.25h1c.14 0 .25-.11.25-.25v-5h5c.14 0 .25-.11.25-.25v-1a.25.25 0 00-.25-.25z' fill='%23fff'/%3E%3C/svg%3E");
}

.input {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  width: auto;
  height: 56px;
  min-width: 0;
  margin: 0;
  padding: 18px 10px 16px;
  background-color: transparent;
  outline: none;
  font-family: $bodyFont;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  border: 0 none;
  border-radius: 0;
}
