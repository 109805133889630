@import '../../style/mixins';
@import '../../style/variables';

.component {
  @include resetButtonStyle;

  background-color: $black;
  padding: 20px 32px;
  border-radius: 8px;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transition: opacity 0.2s ease;

  @include mediaMdMin {
    font-size: 18px;
    line-height: 22px;
  }

  &[disabled] {
    opacity: 0.5;
  }

  @include hoverable {
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      opacity: 0.7;
    }
  }

  &:not(:disabled):active {
    opacity: 0.5;
  }
}
