@import '../../style/variables';

.component {
  border-radius: 8px;
  font-size: 17px;
  line-height: 19px;
}

.error {
  padding: 12px 20px;
  text-align: center;
  color: $red;
  background-color: rgba($red, 0.1);
}

.success {
  padding: 16px 20px;
  color: $green;
  background-color: rgba($green, 0.1);
}

.mark {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 16px;
  min-width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9A9 9 0 110 9a9 9 0 0118 0zM7.96 13.77l6.68-6.68a.58.58 0 000-.82l-.82-.82a.58.58 0 00-.83 0l-5.44 5.44L5 8.35a.58.58 0 00-.83 0l-.82.82a.58.58 0 000 .82l3.78 3.78c.22.22.6.22.82 0z' fill='%2327AE60'/%3E%3C/svg%3E");
  transform: translateY(2px);
}
