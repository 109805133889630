@import '../../style/mixins';
@import '../../style/variables';

.component {
  @include resetButtonStyle;

  display: inline-block;
  padding: 4px;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 14px 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49 7l4.48-4.49.93-.92a.35.35 0 000-.5l-1-.99a.35.35 0 00-.49 0L7 5.52 1.59.1a.35.35 0 00-.5 0l-.99 1a.35.35 0 000 .49L5.52 7 .1 12.41a.35.35 0 000 .5l1 .99c.13.13.35.13.49 0L7 8.49l4.49 4.48.92.93c.14.13.36.13.5 0l.99-1a.35.35 0 000-.49L8.49 7z' fill='%23000'/%3E%3C/svg%3E");

  &[disabled] {
    opacity: 0.5;
  }

  @include hoverable {
    &:not(:disabled):hover,
    &:not(:disabled):focus {
      opacity: 0.7;
    }
  }

  &:not(:disabled):active {
    opacity: 0.5;
  }
}
