@import '../../style/mixins';
@import '../../style/variables';

.component {
  display: block;

  &:focus {
    border-color: $black;
  }
}

.checkbox {
  .check {
    border-radius: 4px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.33.58L4 6.91 1.67 4.6a.29.29 0 00-.4 0l-.69.68a.29.29 0 000 .4L3.8 8.9c.12.1.3.1.41 0l7.22-7.22a.29.29 0 000-.4l-.69-.69a.29.29 0 00-.4 0z' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: 3px 4px;

    &:before {
      border-radius: 4px;
    }
  }

  input:checked + .label .check:before {
    transition: opacity 0.2s ease;
    opacity: 0;
  }
}

.radio {
  .check {
    border-radius: 50%;

    &:before {
      border-radius: 50%;
    }
  }

  input:checked + .label .check:before {
    transition: transform 0.2s ease;
    transform: scale(0.3);
  }
}

.input {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  box-shadow: none;
  border: none;

  &:checked + .label {
    border-color: $black;
  }

  @include hoverable {
    &:focus + .label {
      border-color: $black;
    }
  }
}

.label {
  position: relative;
  padding: 18px 20px 18px 54px;
  background-color: rgba($mystic, 0.6);
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 8px;
  cursor: pointer;

  @include hoverable {
    &:hover {
      border-color: $black;
    }
  }
}

.check {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  left: 20px;
  top: 21px;
  background-color: $black;

  &:before {
    left: -1px;
    top: -1px;
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    background-color: $white;
  }
}
